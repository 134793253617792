import React, { Fragment, useMemo, useState } from "react";
import { FormType, cardBtnType } from "src/helpers/alias";
import walletBtn from "src/assets/img/walletbtn.svg";
import User from "src/assets/img/User.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import { CardButton } from "src/components/Card";
import ProfileCard from "src/components/ProfileCard";
import useCustomLocation from "src/hooks/useCustomLocation";
import { useFetchSingleHQQuery } from "src/api/manageHQApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import HqBranch from "./HqBranch";
import WalletSvg from "src/assets/img/walletbtn.svg";
import { useChangeStatusMutation } from "src/api/manageHQApiSlice";
import {
  CurrencyFormatter,
  SuccessNotification,
  handleDateFormat,
  handleNotification,
  splitByUpperCase,
} from "src/helpers/helperFunction";
import Box from "@mui/material/Box";
import { FlagModal, FormModal, Modal } from "src/components/ModalComp";
import ProfileEditCard from "src/components/EditProfileCard";
import UserWallet from "../User/UserWallet";
import {
  useUpdateStationUserMutation,
  useUpdateUserMutation,
} from "src/api/manageUserApi";
import {
  useFetchHqWalletQuery,
  useLockHqWalletMutation,
  useLockUserWalletMutation,
} from "src/api/walletAPISlice";
import { Button } from "src/components/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { checkPermissions } from "src/components/PermissionRestrictorComp";
import { PERMISSION } from "src/helpers/Constant";
import { useAuth } from "src/hooks/useAuth";

export default function HQPage() {
  const auth = useAuth();
  const { routePath } = useCustomLocation();
  const [cardName, setCardName] = useState<string>("view profile");

  const singleHqResult = useFetchSingleHQQuery({
    id: routePath?.id,
  });
  const [updateStationUserDetails, updateStationUserDetailsResult] =
    useUpdateStationUserMutation();

  const HQData: cardBtnType[] = [
    {
      id: 1,
      icon: User,
      name: "View Profile",
    },

    {
      id: 2,
      icon: walletBtn,
      name: "Admin Info",
    },
    {
      id: 3,
      icon: Attendant,
      name: "Branches",
    },
    {
      id: 5,
      icon: WalletSvg,
      name: "Wallet Info",
    },
  ];

  const handledAPIResponse = useMemo(() => {
    const singleResult = singleHqResult?.currentData?.hqProfile || {};
    return {
      hqInfo: {
        name: singleResult.name,
        email: singleResult.email,
        hqAddress: singleResult.hqAddress,
        state: singleResult.state,
        // walletNumber: singleResult?.wallets?.walletNumber || "",
        phoneNumber: singleResult.phoneNumber,
        totalBranches: singleResult.totalBranches,
        systemCode: singleResult.systemCode,
        status: singleResult.status?.status,
        dateCreated:
          singleResult.createdAt && handleDateFormat(singleResult.createdAt),
      },
      id: singleResult?._id,
      hqUsers: singleResult?.hqUsers,
      logo: singleResult?.logo?.url,
      systemCode: singleResult?.systemCode,
      account: singleResult?.wallet?.availableBalance,
      walletInfo: {
        walletName: singleResult?.wallet?.walletName,
        walletNumber: singleResult?.wallet?.walletNumber,
        bankName: singleResult?.wallet?.bankName,
        status: singleResult?.wallet?.locked,
      },
      stationBranch: singleResult?.stationBranches?.map((branch: any) => ({
        ...branch,
        hqName: singleResult.name,
      })),
    };
  }, [singleHqResult]);

  interface AdminInfoType {
    [index: string]: string | number;
  }
  async function updateAdminProfile(values: FormType) {
    try {
      const response = await updateStationUserDetails({
        id: handledAPIResponse?.hqUsers[0]?._id,
        ...values,
      }).unwrap();
      console.log(response);
      if (response?.status === "success") {
        await singleHqResult.refetch();
        SuccessNotification("User Updated");
        setShowModal(() => false);
      }
    } catch (error: any) {
      handleNotification(error);
    }
  }
  const handleAdminInfo = (data: AdminInfoType) => {
    return {
      firstName: data.firstName,
      lastName: data?.lastName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
    };
  };

  const [status, setStatus] = useState<string>("available");
  const [showModal, setShowModal] = useState<boolean>(false);

  const [changeStatus, changeStatusResult] = useChangeStatusMutation();
  const handleModal = () => setShowModal(() => !showModal);

  const handleStatus = () => {
    setStatus("unavailable");
  };

  const handleSuspendModal = async () => {
    try {
      const response = await changeStatus({
        accountStatus: {
          status:
            handledAPIResponse?.hqUsers?.[0]?.accountStatus?.status ===
            "confirmed"
              ? "deactivated"
              : "confirmed",
          reason:
            handledAPIResponse?.hqUsers?.[0]?.accountStatus?.status ===
            "confirmed"
              ? "deactivated"
              : "confirmed",
        },
        id: handledAPIResponse?.hqUsers[0]._id,
      }).unwrap();

      await singleHqResult.refetch();
      SuccessNotification("User status updated");
    } catch (error: any) {
      setShowModal(() => false);
      handleNotification(error);
    }
    setShowModal(() => false);
  };

  return (
    <section>
      {/* <LoaderContainer /> */}
      <article className="w-full h-fit px-2 pb-10">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3">
          <>
            {HQData.map((dt) => (
              <Fragment key={dt.id}>
                <CardButton
                  name={dt.name}
                  icon={dt.icon}
                  link={dt.link}
                  height={"98px"}
                  activeBtn={cardName}
                  onClick={() => setCardName(dt?.name)}
                />
              </Fragment>
            ))}
          </>
        </div>
        <LoaderContainer data={singleHqResult}>
          {cardName.toLowerCase() === "view profile" ? (
            <ProfileCard
              data={handledAPIResponse?.hqInfo || {}}
              imageURL={handledAPIResponse?.logo || ""}
              showBanner={true}
              secondButtonText={
                handledAPIResponse?.hqUsers?.[0]?.accountStatus?.status ===
                "confirmed"
                  ? "Suspend"
                  : "Unsuspend"
              }
              showSecondButton={
                checkPermissions(auth?.user?.role || "", [
                  PERMISSION.SYSTEM_ADMIN,
                ]) && true
              }
              secondOnClick={() => setShowModal(true)}
              onClick={() => setCardName("edit hq")}
              buttonText={"Edit HQ"}
              showButton={
                checkPermissions(auth?.user?.role || "", [
                  PERMISSION.SYSTEM_ADMIN,
                ]) && true
              }
            />
          ) : null}
          {cardName.toLowerCase() === "admin info" ? (
            <>
              <ProfileCard
                showBanner={false}
                showHeader={true}
                header="HQ Admin Information"
                data={handleAdminInfo(handledAPIResponse?.hqUsers[0]) || {}}
                imageURL=""
                secondOnClick={() => setCardName("view profile")}
                showImage={false}
                flag={true}
                onClick={() => setCardName("edit hq admin")}
                buttonText={"Edit"}
                showButton={
                  checkPermissions(auth?.user?.role || "", [
                    PERMISSION.SYSTEM_ADMIN,
                  ]) && true
                }
                status={status}
                fn={handleStatus}
                makeRequest={handleSuspendModal}
                handleModal={handleModal}
                apiResult={changeStatusResult}
                showModal={showModal} //"status": "suspended"
                suspend={
                  handledAPIResponse?.hqInfo?.status === "pending"
                    ? "Activate"
                    : handledAPIResponse?.hqInfo?.status === "active"
                    ? "Suspend"
                    : "Unsuspend"
                }
              />
            </>
          ) : null}
          {cardName.toLowerCase() === "edit hq" ? (
            <>
              <ProfileEditCard
                showBanner={false}
                showHeader={true}
                showButton={true}
                header="Edit HQ Information"
                data={handleAdminInfo(handledAPIResponse?.hqUsers[0]) || {}}
                imageURL=""
                showImage={false}
                showSecondButton={true}
                flag={true}
                status={status}
                fn={handleStatus}
                secondOnClick={() => setCardName("view profile")}
                secondButtonText={"Cancel"}
                makeRequest={updateAdminProfile}
                handleModal={handleModal}
                apiResult={changeStatusResult}
                showModal={updateStationUserDetailsResult.isLoading}
                suspend={
                  handledAPIResponse?.hqUsers?.[0]?.accountStatus?.status ===
                  "confirmed"
                    ? "deactivated"
                    : "confirmed"
                }
              />
            </>
          ) : null}
          {cardName.toLowerCase() === "edit hq admin" ? (
            <>
              <ProfileEditCard
                showBanner={false}
                showHeader={true}
                showButton={true}
                header="Edit Admin Information"
                data={handleAdminInfo(handledAPIResponse?.hqUsers[0]) || {}}
                imageURL=""
                showImage={false}
                flag={true}
                status={status}
                fn={handleStatus}
                showModal={updateStationUserDetailsResult.isLoading}
                secondOnClick={() => setCardName("admin info")}
                secondButtonText={"Cancel"}
                makeRequest={updateAdminProfile}
                handleModal={handleModal}
                apiResult={updateStationUserDetailsResult}
                suspend={
                  handledAPIResponse?.hqInfo?.status === "pending"
                    ? "Activate"
                    : handledAPIResponse?.hqInfo?.status === "active"
                    ? "Suspend"
                    : "Unsuspend"
                }
              />
            </>
          ) : null}

          {cardName.toLowerCase() === "branches" ? (
            <HqBranch branchInfo={handledAPIResponse?.stationBranch || []} />
          ) : null}
        </LoaderContainer>
        {cardName.toLowerCase() === "wallet info" ? (
          <>
            <HQAccountBalance
              account={handledAPIResponse.account}
              info={handledAPIResponse}
            />
            {/* <UserWallet id={handledAPIResponse?.id} /> */}
          </>
        ) : null}
        {showModal && (
          <Modal>
            <FlagModal
              info={`Are you sure you want to ${
                handledAPIResponse?.hqUsers?.[0]?.accountStatus?.status ===
                "confirmed"
                  ? `Suspend ${handledAPIResponse?.hqInfo?.name}`
                  : `Unsuspend ${handledAPIResponse?.hqInfo?.name}`
              }?`}
              onClose={handleModal}
              onConfirmation={handleSuspendModal}
              showModal={
                changeStatusResult?.isLoading || singleHqResult.isFetching
              }
            />
          </Modal>
        )}
      </article>
    </section>
  );
}

// export const HqAccountBalance = (props: {
//   account: string;
//   id: string;
//   info: { [index: string]: string | any };
// }) => {
//   const { data } = useFetchHqWalletQuery({ id: props.id });
//   return (
//     <div className="p-4 basis-[60%] rounded-[10px] bg-white grid grid-cols-1 gap-x-10 justify-items-center content-center mt-6 pl-6">
//       <div className="text-start text-[#002E66]">
//         <h3 className="text-[14px]my-">Available Balance</h3>
//         <h2 className="text-[24px] text-center font-bold">
//           {CurrencyFormatter(Number(props?.account))}
//         </h2>
//       </div>
//       <div className="w-full mt-4 grid grid-cols-3 my-6">
//         {Object.keys(props?.info?.walletInfo)?.map((dt, i) => (
//           <div key={i + 1}>
//             <h2 className="text-black capitalize">{splitByUpperCase(dt)}</h2>
//             <h2 className="text-[#002E66] capitalize">
//               {props?.info?.walletInfo[dt]}
//             </h2>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

export const HQAccountBalance = (props: {
  account: string;
  info: { [index: string]: string | any };
}) => {
  const { routePath } = useCustomLocation();
  const singleHqResult = useFetchSingleHQQuery({
    id: routePath?.id,
  });
  const [activeTab, setActiveTab] = useState<string>("balance");
  const [walletData, setWalletData] = useState<any>(props.info.walletInfo);
  const [showLockModal, setShowLockModal] = useState<boolean>(false);
  const [isLocking, setIsLocking] = useState<boolean>(true);
  const auth = useAuth();
  const toggleTab = (tab: string) => setActiveTab(tab);

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletData({ ...walletData, [e.target.name]: e.target.value });
  };

  const [lockHqWallet, lockHqWalletDetails] = useLockHqWalletMutation();

  const handleLockWallet = async () => {
    try {
      const response = await lockHqWallet({
        id: props?.info?.id,
        locked: true,
        canWithdraw: true,
      }).unwrap();
      SuccessNotification("Wallet Locked");
      singleHqResult.refetch();
      setShowLockModal(false);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const handleUnlockWallet = async () => {
    try {
      const response = await lockHqWallet({
        id: props?.info?.id,
        locked: false,
        canWithdraw: false,
      }).unwrap();
      if (response.status === "success") {
        SuccessNotification("Wallet Unlocked");
      }

      singleHqResult.refetch();
      setShowLockModal(false);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const btnClass =
    "h-full font-bold text-white rounded-lg px-6 py-2 bg-[#002E66] hover:bg-[#004b99] transition duration-300";

  const handleChange = (event: any) => {
    const t: string = event.target.textContent;
    t.toLowerCase().includes("balance")
      ? setActiveTab("balance")
      : setActiveTab("lock");
  };
  return (
    <>
      <div className="p-6 basis-[60%] rounded-[10px] bg-white mt-1 shadow-lg">
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={activeTab}
            onClick={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              sx={{
                fontSize: 14,
              }}
              key={"balance"}
              value={"balance"}
              label={"Balance"}
            />
            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) && (
              <Tab
                sx={{
                  fontSize: 14,
                }}
                key={"lock"}
                value={"lock"}
                label={"Lock Wallet"}
              />
            )}
          </Tabs>
        </Box>
        {/* <div className="flex justify-between items-center mb-1">
          <div className="flex space-x-4">
            <Button
              text="Balance"
              className={`${btnClass} ${
                activeTab === "balance" ? "bg-[#004b99]" : ""
              }`}
              type="button"
              showIcon={false}
              onClick={() => toggleTab("balance")}
            />
            <Button
              text="Lock/Unlock"
              className={`${btnClass} ${
                activeTab === "lock" ? "bg-[#004b99]" : ""
              }`}
              type="button"
              showIcon={false}
              onClick={() => toggleTab("lock")}
            />
          </div>
        </div> */}

        {activeTab === "balance" && (
          <div className="grid gap-1">
            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) && (
              <div className="text-center text-[#002E66]">
                <h3 className="text-[14px]">Available Balance</h3>
                <h2 className="text-[24px] font-bold">
                  {CurrencyFormatter(Number(props.account))}
                </h2>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Object.keys(props.info.walletInfo).map((key, i) => (
                <div
                  key={i + 1}
                  className="bg-gray-100 p-4 pt-2 pb-1 rounded-lg"
                >
                  <h2 className="text-black capitalize">
                    {splitByUpperCase(key)}
                  </h2>
                  <h2 className="text-[#002E66]">
                    {key === "status"
                      ? props.info.walletInfo[key]
                        ? "Locked"
                        : "Not Locked"
                      : props.info.walletInfo[key]}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        )}
        {checkPermissions(auth?.user?.role || "", [
          PERMISSION.SYSTEM_ADMIN,
        ]) && (
          <>
            {activeTab === "lock" && (
              <div className="flex w-full flex-col gap-4 items-center justify-between">
                {!props.info.walletInfo.status && (
                  <div className="flex w-full items-center justify-between">
                    <p className="my-4">Wallet is unlocked, lock?</p>
                    <Button
                      type="button"
                      text="Lock Wallet"
                      className={btnClass}
                      showIcon={false}
                      onClick={() => {
                        setIsLocking(true);
                        setShowLockModal(true);
                      }}
                    />
                  </div>
                )}
                {props.info.walletInfo.status && (
                  <div className="flex w-full items-center justify-between">
                    <p className="my-4">Wallet is locked, unlock?</p>
                    <Button
                      type="button"
                      text="Unlock Wallet"
                      className={btnClass}
                      showIcon={false}
                      onClick={() => {
                        setIsLocking(false);
                        setShowLockModal(true);
                      }}
                    />{" "}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <UserWallet type="station_hq" />

      {checkPermissions(auth?.user?.role || "", [PERMISSION.SYSTEM_ADMIN]) &&
        showLockModal && (
          <FormModal
            name={isLocking ? "Lock Wallet" : "Unlock Wallet"}
            onClick={() => setShowLockModal(false)}
          >
            <div className="p-4">
              {/* <h2 className="text-lg font-bold">
              {isLocking ? "Lock Wallet" : "Unlock Wallet"}
            </h2> */}
              <p className="my-4">
                Are you sure you want to {isLocking ? "lock" : "unlock"} the
                wallet?
              </p>
              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  text="Cancel"
                  className="px-4 py-2 bg-gray-500 rounded"
                  showIcon={false}
                  onClick={() => setShowLockModal(false)}
                />
                <Button
                  type="button"
                  showModal={lockHqWalletDetails.isLoading}
                  text="Confirm"
                  className="px-4 py-2 bg-[#002E66] rounded text-white"
                  showIcon={false}
                  onClick={isLocking ? handleLockWallet : handleUnlockWallet}
                />
              </div>
            </div>
          </FormModal>
        )}
    </>
  );
};
