import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useRef, useState } from "react";

const GoogleLocationInput = ({
  getLat,
  getLong,
  getAddress,
}: {
  getLat: (arg: number) => void;
  getLong: (arg: number) => void;
  getAddress: (arg: string) => void;
}) => {
  const originRef = useRef();
  const [result, setResult] = useState<any>(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCDfI1GOcaZ2W3xQZyWwN_d2ZUzMufGSS4",
    libraries: ["places", "geometry"],
  });
  const onLoad = (autoComplete: any) => {
    setResult(autoComplete);

    const nigeriaBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(4.21, 2.68),
      new google.maps.LatLng(13.9, 14.67)
    );
    autoComplete.setBounds(nigeriaBounds);
    autoComplete.setOptions({ strictBounds: true });
  };
  const onLocationSelected = () => {
    const place = result?.getPlace();

    getAddress(place.formatted_address as any);
    getLat(place.geometry.location.lat());
    getLong(place.geometry.location.lng());
  };

  return (
    <div>
      {isLoaded ? (
        <Autocomplete onLoad={onLoad} onPlaceChanged={onLocationSelected}>
          <>
            <label className="block mb-[6px] text-black text-start font-normal text-[14px]  ml-5 my-6">
              Enter Address
            </label>
            <input
              className="h-[54px] rounded-[38px] w-full border border-gray-300 px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              ref={originRef as any}
              name="origin"
            />
          </>
        </Autocomplete>
      ) : null}
    </div>
  );
};

export default GoogleLocationInput;
